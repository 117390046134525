import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

const useText = () => {
	const texts = useSelector((redux) => redux.texts);
	const router = useRouter();
	const language = router.locale;

	const getText = useCallback(
		(key) => {
			if (!texts) return key;
			if (texts[key] === undefined) return '<<' + key + '>>';
			if (texts[key][language] === undefined) return '<<' + language + ' of ' + key + '>>';
			return texts[key][language];
		},
		[texts, language],
	);

	return {
		getText,
	};
};
export default useText;

export const withGetText = (Component) => {
	const InnerComponent = (props) => {
		const { getText } = useText();
		return <Component getText={getText} {...props} />;
	};

	return InnerComponent;
};
